import Rainbow from "rainbowvis.js";
import React from "react";
import { PercentageBar } from "../../../components";

const getChartColorRange = (color, number) => {
  const rainbow = new Rainbow();
  rainbow.setSpectrum(color || "#131c24", "#FFFFFF");
  rainbow.setNumberRange(0, number > 1 ? number : 1);
  let arr = [];
  for (let i = 0; i < number; i += 1) {
    arr = arr.concat(`#${rainbow.colorAt(i)}`);
  }
  return arr;
};

function LiabilitiesChart({ defaultTitle, color, parts = [] }) {
  const liabilitiesChartColor = getChartColorRange(color, parts.length);

  return (
    <div className="liabilities-chart">
      <PercentageBar
        defaultTitle={defaultTitle}
        isCustomChartColor
        chartColors={liabilitiesChartColor}
        parts={parts}
      />
      {parts.length === 0 && (
        <div className="liabilities-chart__part liabilities-chart__part--disabled" />
      )}
      <div className="liabilities-chart__legends">
        {parts.map((part, index) => (
          <div className="legend" key={part.id || part.title}>
            <div className="legend__title">{part.title}</div>
            <div className="legend__color-label" style={{ "--index": index }} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default LiabilitiesChart;
